import BaseController from "../utils/base_controller"

export default class BaseMediaPlayerController extends BaseController {
    static targets = ["player", "speedControl", "currentSpeed"]
    static values = {
        playerClass: String,
        debug: Boolean
    }

    connect() {
        super.connect()
        this.log("Media player controller connected", "info")
        this.setupAudio()
        this.setupPlyr()
        this.setupEventListeners()
        this.setupSpeedControl()
    }

    disconnect() {
        this.removeEventListeners()
        this.log("Media player controller disconnected", "info")
        super.disconnect()
    }

    setupAudio() {
        this.audio = document.getElementById(this.playerClassValue)
        if (!this.audio) {
            this.log(`Audio element with id '${this.playerClassValue}' not found`, "warn")
        }
    }

    setupPlyr() {
        if (this.hasPlayerTarget) {
            try {
                this.player = new Plyr(this.playerTarget, this.plyrOptions)
                this.player.volume = 0.6
                this.log("Plyr initialized successfully", "debug")
            } catch (error) {
                this.log(`Error initializing Plyr: ${error}`, "error")
            }
        } else {
            this.log("Player target not found", "warn")
        }
    }

    setupEventListeners() {
        if (this.audio) {
            if (this.audio.readyState >= 2) {
                this.onAudioLoaded()
            } else {
                this.audio.addEventListener('loadedmetadata', this.onAudioLoaded.bind(this))
            }
        }

        if (this.player) {
            this.player.on('ratechange', this.onRateChange.bind(this))
            this.player.on('play', this.onPlay.bind(this))
        }
    }

    removeEventListeners() {
        if (this.audio) {
            this.audio.removeEventListener('loadedmetadata', this.onAudioLoaded.bind(this))
        }

        if (this.player) {
            this.player.off('ratechange', this.onRateChange.bind(this))
            this.player.off('play', this.onPlay.bind(this))
        }
    }

    setupSpeedControl() {
        if (this.hasSpeedControlTarget) {
            this.speedControlTarget.addEventListener('change', this.changeSpeed.bind(this))
        }
    }

    onAudioLoaded() {
        this.log(`Audio loaded, duration: ${this.audio.duration}`, "debug")
    }

    onRateChange() {
        if (this.hasCurrentSpeedTarget && this.player) {
            this.currentSpeedTarget.innerText = this.player.speed.toFixed(1)
        }
        if (this.hasSpeedControlTarget && this.player) {
            this.speedControlTarget.value = this.player.speed
        }
        this.log(`Playback rate changed to ${this.player.speed}`, "debug")
    }

    changeSpeed(event) {
        if (this.player) {
            this.player.speed = parseFloat(event.target.value)
            this.log(`Speed changed to ${this.player.speed}`, "debug")
        }
    }

    onPlay() {
        // Dispatch a custom event when the player starts
        document.dispatchEvent(new CustomEvent('plyr:play'))
        this.log("Player started playing, dispatched 'plyr:play' event", "debug")
    }

    get plyrOptions() {
        return {
            preload: 'metadata',
            controls: [
                'play-large', 'restart', 'play', 'progress', 'current-time',
                'duration', 'mute', 'volume', 'settings'
            ],
            speed: {
                selected: 1,
                options: [0.7, 0.8, 0.9, 1, 1.1, 1.2]
            },
            volume: 0.6
        }
    }
}