import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["player"]
    static values = {
        delay: { type: Number, default: 2000 },
        minimumDelay: { type: Number, default: 2000 }
    }

    connect() {
        console.log("=== Autoplay Helper Connected ===")

        if (this.shouldAutoplay()) {
            console.log("Should autoplay - scheduling...")
            this.scheduleAutoplay()
        } else {
            console.log("Should not autoplay")
        }
    }

    disconnect() {
        console.log("=== Autoplay Helper Disconnected ===")
        console.log("Element:", this.element)
    }

    shouldAutoplay() {
        const should = this.element.dataset.autoplayEnabled === 'true'
        return should
    }

    scheduleAutoplay() {
        const effectiveDelay = Math.max(this.delayValue, this.minimumDelayValue)

        setTimeout(() => {
            const playButton = this.element.querySelector('.plyr__controls [data-plyr="play"]')
            if (playButton) {
                playButton.click()
            }
        }, effectiveDelay)
    }
}