// app/javascript/controllers/flash_toast_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["toast"]
    static values = {
        dismissAfter: { type: Number, default: 5000 } // Time in milliseconds
    }

    connect() {
        // Add initial hidden state
        this.toastTarget.style.opacity = "0"
        this.toastTarget.style.transform = "translate(-50%, 1rem) scale(0.95)"

        // Start enter animation in next frame
        requestAnimationFrame(() => {
            // Trigger enter animation
            this.enter()

            // Set up auto-dismiss if enabled
            if (this.dismissAfterValue > 0) {
                this.autoDismissTimeout = setTimeout(() => {
                    this.dismiss()
                }, this.dismissAfterValue)
            }
        })
    }

    disconnect() {
        // Clean up timeout if it exists
        if (this.autoDismissTimeout) {
            clearTimeout(this.autoDismissTimeout)
        }
    }

    // Handle manual dismissal
    dismiss() {
        // Clear any existing timeout
        if (this.autoDismissTimeout) {
            clearTimeout(this.autoDismissTimeout)
        }

        // Start exit animation
        this.leave()
    }

    // Enter animation
    enter() {
        const element = this.toastTarget

        // Add transition classes
        element.classList.add(
            "transform",
            "transition-all",
            "duration-300",
            "ease-out"
        )

        // Trigger animation
        requestAnimationFrame(() => {
            element.style.opacity = "1"
            element.style.transform = "translate(-50%, 0) scale(1)"
        })
    }

    // Leave animation
    leave() {
        const element = this.toastTarget

        // Add transition classes for exit
        element.classList.add(
            "transform",
            "transition-all",
            "duration-500",
            "ease-in"
        )

        // Trigger animation
        requestAnimationFrame(() => {
            element.style.opacity = "0"
            element.style.transform = "translate(-50%, -1rem) scale(0.95)"
        })

        // Remove element after animation
        setTimeout(() => {
            element.remove()
        }, 500) // Match duration in the CSS
    }

    // Pause auto-dismiss on hover
    mouseEnter() {
        if (this.autoDismissTimeout) {
            clearTimeout(this.autoDismissTimeout)
        }
    }

    // Resume auto-dismiss on mouse leave
    mouseLeave() {
        if (this.dismissAfterValue > 0) {
            this.autoDismissTimeout = setTimeout(() => {
                this.dismiss()
            }, this.dismissAfterValue)
        }
    }
}