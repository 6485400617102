// Zoomist Controller
//
// This controller initializes a Zoomist instance for image zooming functionality.
// It waits for the image to load before initializing and includes retry logic.

import BaseController from "../utils/base_controller"
import Zoomist from 'zoomist'

export default class extends BaseController {
    static targets = ["container"]

    connect() {
        super.connect()
        this.initZoomist()
        this.log("Zoomist initialization started", "info")
    }

    initZoomist(retries = 3) {
        const img = this.containerTarget.querySelector('img')
        if (!img) {
            this.log("No image found in container", "warn")
            return
        }

        if (img.complete && img.naturalWidth > 0) {
            this.createZoomistInstance(img)
        } else if (retries > 0) {
            this.log(`Image not loaded, retrying in 500ms. Retries left: ${retries - 1}`, "debug")
            setTimeout(() => this.initZoomist(retries - 1), 500)
        } else {
            this.log("Failed to initialize Zoomist after all retries", "error")
        }
    }

    createZoomistInstance(img) {
        new Zoomist(this.containerTarget, {
            maxScale: 2,
            minScale: 0.7,  // This allows zooming out to 50% of original size
            initScale: 1.0,     // This sets the initial zoom level to 100%
            slider: true,
            pinchable: true,
            bounds: false,
            draggable: true,
            wheelable: true
        })


        this.log("Zoomist instance created successfully", "info")
    }
}