import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["button"]
    static values = {
        atomId: Number,
        isRecording: Boolean,
        startPath: String,
        stopPath: String,
        autoStart: Boolean,
        testMode: Boolean
    }

    connect() {
        super.connect()
        this.updateButtonState()
        document.addEventListener('recording-started', this.handleGlobalRecordingStarted.bind(this))
        document.addEventListener('recording-stopped', this.handleGlobalRecordingStopped.bind(this))
        window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this))

        if (this.autoStartValue) {
            this.startRecording(false)
        }
    }

    disconnect() {
        if (this.isRecordingValue) {
            this.stopRecording(false)
        }
        document.removeEventListener('recording-started', this.handleGlobalRecordingStarted.bind(this))
        document.removeEventListener('recording-stopped', this.handleGlobalRecordingStopped.bind(this))
        window.removeEventListener('beforeunload', this.handleBeforeUnload.bind(this))
        super.disconnect()
    }

    toggleRecording() {
        if (this.isRecordingValue) {
            this.stopRecording(true)
        } else {
            this.startRecording(true)
        }
    }

    startRecording(isUserInitiated = true) {
        // Immediately update UI
        this.isRecordingValue = true
        this.updateButtonState()

        if (isUserInitiated) {
            document.dispatchEvent(new CustomEvent('recording-started', { detail: { atomId: this.atomIdValue } }))
        }

        // Send request to server
        this.sendRecordingRequest(this.startPathValue, 'POST')
    }

    stopRecording(isUserInitiated = true) {
        // Immediately update UI
        this.isRecordingValue = false
        this.updateButtonState()

        if (isUserInitiated) {
            document.dispatchEvent(new CustomEvent('recording-stopped', { detail: { atomId: this.atomIdValue } }))
        }

        // Send request to server
        this.sendRecordingRequest(this.stopPathValue, 'POST')
    }

    sendRecordingRequest(path, method) {
        if (this.testModeValue) {
            // Simulate an error for testing
            setTimeout(() => {
                this.log("Simulated error in test mode")
                this.setErrorState()
            }, 1000);  // Delay of 1 second to simulate network request
            return;
        }

        const csrfToken = this.getCsrfToken()
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        if (csrfToken) {
            headers['X-CSRF-Token'] = csrfToken
        }

        fetch(path, {
            method: method,
            headers: headers,
            body: JSON.stringify({
                currently_recording_atom_id: this.getCurrentlyRecordingAtomId()
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then(data => {
                this.log("Recording request sent successfully")
            })
            .catch(error => {
                this.log("Error sending recording request: " + error)
                this.setErrorState()
            })
    }

    updateButtonState() {
        this.buttonTarget.classList.remove('recording', 'not-recording', 'error')
        if (this.isRecordingValue) {
            this.buttonTarget.classList.add('recording')
        } else {
            this.buttonTarget.classList.add('not-recording')
        }
    }

    setErrorState() {
        this.buttonTarget.classList.remove('recording', 'not-recording')
        this.buttonTarget.classList.add('error')
        // Reset error state after 3 seconds
        setTimeout(() => {
            this.updateButtonState()
        }, 3000)
    }

    handleGlobalRecordingStarted(event) {
        if (event.detail.atomId !== this.atomIdValue) {
            this.isRecordingValue = false
            this.updateButtonState()
        }
    }

    handleGlobalRecordingStopped(event) {
        if (event.detail.atomId === this.atomIdValue) {
            this.isRecordingValue = false
            this.updateButtonState()
        }
    }

    handleBeforeUnload() {
        if (this.isRecordingValue) {
            this.stopRecording(false)
        }
    }

    getCsrfToken() {
        const metaTag = document.querySelector('meta[name="csrf-token"]')
        return metaTag ? metaTag.content : null
    }

    getCurrentlyRecordingAtomId() {
        const recordingElement = document.querySelector('[data-recording-button-is-recording-value="true"]')
        return recordingElement ? recordingElement.dataset.recordingButtonAtomIdValue : null
    }

    log(message) {
        console.log(`[RecordingButton] ${message}`)
    }
}