import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = ["cloud", "tagsInput"]
    static values = {
        spinnerId: String,
        frameId: String
    }

    connect() {
        super.connect()
        this.selectedTags = new Set(this.getInitialTags())
        this.updateTagAppearance()
        this.boundHideSpinner = this.hideSpinner.bind(this)
        this.log("Tag cloud initialized with tags: " + Array.from(this.selectedTags).join(', '), "debug")
    }

    disconnect() {
        const frame = document.getElementById(this.frameIdValue)
        if (frame) {
            frame.removeEventListener('turbo:frame-render', this.boundHideSpinner)
        }
        this.log("Tag cloud controller disconnected", "debug")
        super.disconnect()
    }

    getInitialTags() {
        if (this.hasTagsInputTarget) {
            return this.tagsInputTarget.value.split(',').filter(Boolean)
        } else {
            this.log("tagsInput target not found, initializing with empty set", "warn")
            return []
        }
    }

    toggleTag(event) {
        event.preventDefault()
        const tag = event.currentTarget.dataset.tag
        if (this.selectedTags.has(tag)) {
            this.selectedTags.delete(tag)
            this.log(`Tag removed: ${tag}`, "debug")
        } else {
            this.selectedTags.add(tag)
            this.log(`Tag added: ${tag}`, "debug")
        }
        this.updateTagAppearance()
        this.updateSelectedTags()
        this.submit()
    }

    updateTagAppearance() {
        if (this.hasCloudTarget) {
            this.cloudTarget.querySelectorAll('.tag').forEach(tagElement => {
                const tag = tagElement.dataset.tag
                if (this.selectedTags.has(tag)) {
                    tagElement.classList.add('selected')
                } else {
                    tagElement.classList.remove('selected')
                }
            })
            this.log("Tag appearance updated", "debug")
        } else {
            this.log("Cloud target not found, unable to update tag appearance", "warn")
        }
    }

    updateSelectedTags() {
        if (this.hasTagsInputTarget) {
            this.tagsInputTarget.value = Array.from(this.selectedTags).join(',')
            this.log("Selected tags updated: " + this.tagsInputTarget.value, "debug")
        } else {
            this.log("tagsInput target not found, unable to update selected tags", "warn")
        }
    }

    submit() {
        this.showSpinner()
        if (this.element.tagName === 'FORM') {
            this.element.requestSubmit()
        } else {
            const form = this.element.closest('form')
            if (form) {
                form.requestSubmit()
            } else {
                this.log("No form found to submit", "error")
            }
        }
        this.log("Form submitted", "info")
    }

    reset(event) {
        event.preventDefault()
        this.selectedTags.clear()
        this.updateTagAppearance()
        this.updateSelectedTags()
        if (this.element.tagName === 'FORM') {
            this.element.reset()
        } else {
            const form = this.element.closest('form')
            if (form) {
                form.reset()
            }
        }
        this.submit()
        this.log("Tag cloud reset", "info")
    }

    showSpinner() {
        const spinner = document.getElementById(this.spinnerIdValue)
        if (spinner) {
            spinner.classList.remove('hidden')
            this.log("Spinner shown", "debug")
        } else {
            this.log(`Spinner with id '${this.spinnerIdValue}' not found`, "warn")
        }
        const frame = document.getElementById(this.frameIdValue)
        if (frame) {
            frame.addEventListener('turbo:frame-render', this.boundHideSpinner, { once: true })
        } else {
            this.log(`Frame with id '${this.frameIdValue}' not found`, "warn")
        }
    }

    hideSpinner() {
        const spinner = document.getElementById(this.spinnerIdValue)
        if (spinner) {
            spinner.classList.add('hidden')
            this.log("Spinner hidden", "debug")
        } else {
            this.log(`Spinner with id '${this.spinnerIdValue}' not found`, "warn")
        }
    }
}