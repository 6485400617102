// click_scale_image_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["image"]

    connect() {
        this.overlay = document.getElementById('global-image-overlay')
        if (!this.overlay) {
            this.overlay = document.createElement('div')
            this.overlay.id = 'global-image-overlay'
            this.overlay.className = 'fixed inset-0 bg-black bg-opacity-50 hidden flex items-center justify-center z-50'
            document.body.appendChild(this.overlay)
        }
    }

    toggleScale(event) {
        event.preventDefault()
        const img = this.imageTarget

        if (this.overlay.classList.contains('hidden')) {
            // Scale up
            const scaledImg = img.cloneNode(true)
            scaledImg.classList.add('scaled-image')
            scaledImg.classList.remove('cursor-pointer')
            this.overlay.innerHTML = ''
            this.overlay.appendChild(scaledImg)
            this.overlay.classList.remove('hidden')
            this.overlay.addEventListener('click', this.hideOverlay.bind(this))
        } else {
            this.hideOverlay()
        }
    }

    hideOverlay() {
        this.overlay.classList.add('hidden')
        this.overlay.innerHTML = ''
    }
}