// app/javascript/controllers/bp-admin/accounts_dropdown_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    fetchAccounts(event) {
        const userId = event.target.value;

        if (!userId) {
            const frame = document.getElementById("accounts_dropdown_select");
            frame.innerHTML = this.emptyAccountSelect();
            return;
        }

        // We don't need to handle the response manually anymore
        // Turbo will automatically process the turbo-stream response
        fetch(`/business_processes/vip_students/accounts_for_user?user_id=${userId}`, {
            headers: {
                Accept: "text/vnd.turbo-stream.html",
                "X-Requested-With": "XMLHttpRequest"
            }
        })
            .catch(error => {
                console.error('Error fetching accounts:', error);
                const frame = document.getElementById("accounts_dropdown_select");
                frame.innerHTML = this.emptyAccountSelect();
            });
    }

    emptyAccountSelect() {
        return `
            <div class="form-group mb-4">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" for="vip_student_account_id">Account</label>
                <select class="form-control w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 dark:bg-gray-800 dark:border-gray-600 dark:focus:border-primary-400" name="vip_student[account_id]" id="vip_student_account_id">
                    <option value="">Select an account</option>
                </select>
            </div>
        `;
    }
}