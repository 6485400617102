import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["minutes", "seconds"]
    static values = {
        updateUrl: String
    }

    update() {
        const minutes = this.minutesTarget.value
        const seconds = this.secondsTarget.value
        const data = new FormData()
        data.append('start_minutes', minutes)
        data.append('start_seconds', seconds)

        fetch(this.updateUrlValue, {
            method: 'PATCH',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            body: data
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log('Start time updated successfully');
                } else {
                    console.error('Failed to update start time:', data.errors);
                }
            })
            .catch(error => console.error('Error:', error))
    }
}