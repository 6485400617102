// Core Atom Problem Report Controller
//
// This controller manages the problem reporting functionality for core atoms.
// It handles form toggling, report submission, and updates the UI based on the server response.

import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = [ "form", "redDot", "content" ]
    static values = {
        atomId: Number
    }

    connect() {
        super.connect()
        this.log(`Atom ID: ${this.atomIdValue}`, "debug")
    }

    disconnect() {
        super.disconnect()
    }

    toggleForm() {
        const newDisplay = this.formTarget.style.display === 'none' ? 'block' : 'none'
        this.formTarget.style.display = newDisplay
        this.log(`Form display toggled to: ${newDisplay}`, "debug")
    }

    submitReport(event) {
        event.preventDefault();
        this.log("Submitting problem report", "info")

        const form = event.target;
        const formData = new FormData(form);

        this.log("Sending fetch request for problem report", "debug")
        fetch(form.action, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                'Accept': 'application/json'
            }
        })
            .then(response => {
                this.log(`Received response with status: ${response.status}`, "debug")
                return response.json()
            })
            .then(data => {
                if (data.success) {
                    this.handleSuccessfulSubmission(data)
                } else {
                    this.handleFailedSubmission(data)
                }
            })
            .catch(error => {
                this.log(`Fetch error: ${error}`, "error")
            });
    }

    handleSuccessfulSubmission(data) {
        this.log("Problem report submitted successfully", "info")
        this.formTarget.style.display = 'none';
        this.element.querySelector('.atomic-code').classList.add('reported');
        this.redDotTarget.style.display = 'inline-block';
        this.contentTarget.textContent = data.content;

        if (data.is_new) {
            this.log("New report created", "debug")
            // Handle new report creation (if needed)
        } else {
            this.log("Existing report updated", "debug")
            // Handle report update (if needed)
        }
    }

    handleFailedSubmission(data) {
        this.log(`Error submitting problem report: ${data.errors.join(', ')}`, "error")
        alert('Error submitting problem report: ' + data.errors.join(', '));
    }
}