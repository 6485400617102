// loading_animation_controller.js
import BaseController from "../utils/base_controller"

/**
 * LoadingAnimationBasicLinkClick Controller
 *
 * This Stimulus controller manages loading animations for link clicks.
 * It shows a spinner for non-Turbo links and handles various edge cases.
 *
 * Features:
 * 1. Shows a spinner for non-Turbo link clicks
 * 2. Hides spinner on page load or after a timeout
 * 3. Ignores clicks that open in new tabs/windows
 * 4. Customizable spinner ID, hide delay, and timeout duration
 * 5. Debug mode for logging
 *
 * Usage:
 * <div data-controller="loading-animation-basic-link-click"
 *      data-loading-animation-basic-link-click-debug-value="false"
 *      data-loading-animation-basic-link-click-spinner-id-value="my-custom-spinner"
 *      data-loading-animation-basic-link-click-hide-delay-value="500"
 *      data-loading-animation-basic-link-click-timeout-value="30000">
 *   <a href="/some-page" data-loading-animation-basic-link-click-target="link">Click me</a>
 *   <a href="/another-page" data-turbo="false" data-loading-animation-basic-link-click-target="link">Non-Turbo Link</a>
 * </div>
 * <div id="my-custom-spinner" class="hidden">Loading...</div>
 *
 * Note: Ensure you have a spinner element in your HTML with the specified ID.
 * The spinner should have a 'hidden' class that can be toggled to show/hide it.
 */
export default class LoadingAnimationBasicLinkClick extends BaseController {
    static targets = ["link"]
    static values = {
        spinnerId: { type: String, default: 'global-spinner' },
        deactivateDelay: { type: Number, default: 200 },
        timeout: { type: Number, default: 20000 }
    }

    connect() {
        super.connect()
        this.log(`Spinner ID: ${this.spinnerIdValue}`)
        this.spinnerTimeout = null
        this.bindEvents()
        this.disableInstantClickForNonTurboLinks()
    }

    bindEvents() {
        this.handleClick = this.handleClick.bind(this)
        this.onPageLoad = this.onPageLoad.bind(this)

        this.linkTargets.forEach(link => {
            link.addEventListener('click', this.handleClick)
        })
    }

    unbindEvents() {
        this.linkTargets.forEach(link => {
            link.removeEventListener('click', this.handleClick)
        })
        window.removeEventListener('load', this.onPageLoad)
    }

    disableInstantClickForNonTurboLinks() {
        this.linkTargets.forEach(link => {
            if (link.getAttribute('data-turbo') === 'false') {
                link.setAttribute('data-no-instant', '')
            }
        })
    }

    enableInstantClickForNonTurboLinks() {
        this.linkTargets.forEach(link => {
            if (link.getAttribute('data-turbo') === 'false') {
                link.removeAttribute('data-no-instant')
            }
        })
    }

    handleClick(event) {
        const link = event.currentTarget

        if (event.ctrlKey || event.metaKey || link.target === '_blank') {
            this.log('Click ignored: new tab/window')
            return
        }

        if (link.getAttribute('data-turbo') === 'false') {
            this.log('Non-Turbo link clicked, activating spinner')
            this.activateSpinner()
        } else {
            this.log('Turbo-enabled link clicked, not activating spinner')
            return
        }

        this.spinnerTimeout = setTimeout(() => {
            this.log('Page load timeout, deactivating spinner')
            this.deactivateSpinner()
        }, this.timeoutValue)

        window.addEventListener('load', this.onPageLoad, { once: true })
    }

    onPageLoad() {
        this.log('Page loaded, deactivating spinner')
        this.deactivateSpinnerWithDelay()
    }

    activateSpinner() {
        this.log('activateSpinner called')
        const spinnerWrapper = this.getSpinnerElement()
        if (spinnerWrapper) {
            const atomSpinner = spinnerWrapper.querySelector('.atom-spinner')
            if (atomSpinner) {
                atomSpinner.classList.add('active')
                this.log("Spinner activated")
            } else {
                this.log("'.atom-spinner' element not found inside the spinner wrapper", "error")
            }
        } else {
            this.log(`Spinner wrapper with id '${this.spinnerIdValue}' not found`, "error")
        }
    }

    deactivateSpinner() {
        this.log('deactivateSpinner called')
        const spinnerWrapper = this.getSpinnerElement()
        if (spinnerWrapper) {
            const atomSpinner = spinnerWrapper.querySelector('.atom-spinner')
            if (atomSpinner) {
                atomSpinner.classList.remove('active')
                this.log("Spinner deactivated")
            } else {
                this.log("'.atom-spinner' element not found inside the spinner wrapper", "error")
            }
        } else {
            this.log(`Spinner wrapper with id '${this.spinnerIdValue}' not found`, "error")
        }
    }

    deactivateSpinnerWithDelay() {
        this.log(`Deactivating spinner with delay of ${this.deactivateDelayValue}ms`)
        clearTimeout(this.deactivateTimeout) // Clear any existing timeout
        this.deactivateTimeout = setTimeout(() => {
            this.deactivateSpinner()
        }, this.deactivateDelayValue)
    }

    getSpinnerElement() {
        if (!this.spinnerIdValue) {
            this.log("Spinner ID is not set", "error")
            return null
        }
        const spinner = document.getElementById(this.spinnerIdValue)
        if (!spinner) {
            this.log(`Spinner element with id '${this.spinnerIdValue}' not found in the DOM`, "error")
        }
        return spinner
    }

    clearTimeout() {
        if (this.spinnerTimeout) {
            clearTimeout(this.spinnerTimeout)
            this.spinnerTimeout = null
        }
    }
}