// loading_animation_turbo_click_controller.js
import BaseController from "../utils/base_controller"

/**
 * LoadingAnimationTurboClick Controller
 *
 * This Stimulus controller manages loading animations for Turbo interactions,
 * including link clicks, form submissions, and frame renders.
 *
 * Features:
 * 1. Activates a spinner for Turbo navigation events
 * 2. Deactivates spinner on various Turbo lifecycle events
 * 3. Handles errors and edge cases
 * 4. Provides configurable deactivate delay
 * 5. Debug logging
 *
 * Usage:
 * <div data-controller="loading-animation-turbo-click"
 *      data-loading-animation-turbo-click-debug-value="false"
 *      data-loading-animation-turbo-click-spinner-id-value="global-spinner"
 *      data-loading-animation-turbo-click-deactivate-delay-value="200">
 *   <!-- Your Turbo-enabled content here -->
 * </div>
 * <div id="global-spinner" class="atom-spinner">Loading...</div>
 *
 * Note: Ensure you have a spinner element in your HTML with the specified ID.
 * The spinner should have an 'atom-spinner' class. The 'active' class will be toggled to activate/deactivate it.
 */
export default class LoadingAnimationTurboClick extends BaseController {
    static values = {
        spinnerId: { type: String, default: 'global-spinner' },
        deactivateDelay: { type: Number, default: 200 },
    }

    connect() {
        super.connect()
        this.log("LoadingAnimationTurboClick Controller connected")
        this.log(`Spinner ID: ${this.spinnerIdValue}`)
        this.bindEvents()
    }

    disconnect() {
        this.unbindEvents()
        this.log("LoadingAnimationTurboClick Controller disconnected")
        super.disconnect()
    }

    bindEvents() {
        this.handleBeforeFetchRequest = this.handleBeforeFetchRequest.bind(this)
        this.handleFetchRequestError = this.handleFetchRequestError.bind(this)
        this.handleBeforeRender = this.handleBeforeRender.bind(this)
        this.handleRender = this.handleRender.bind(this)
        this.handleFrameRender = this.handleFrameRender.bind(this)
        this.handleBeforeFetchResponse = this.handleBeforeFetchResponse.bind(this)
        this.handleSubmitEnd = this.handleSubmitEnd.bind(this)

        document.addEventListener("turbo:before-fetch-request", this.handleBeforeFetchRequest)
        document.addEventListener("turbo:fetch-request-error", this.handleFetchRequestError)
        document.addEventListener("turbo:before-render", this.handleBeforeRender)
        document.addEventListener("turbo:render", this.handleRender)
        document.addEventListener("turbo:frame-render", this.handleFrameRender)
        document.addEventListener("turbo:before-fetch-response", this.handleBeforeFetchResponse)
        document.addEventListener("turbo:submit-end", this.handleSubmitEnd)
    }

    unbindEvents() {
        document.removeEventListener("turbo:before-fetch-request", this.handleBeforeFetchRequest)
        document.removeEventListener("turbo:fetch-request-error", this.handleFetchRequestError)
        document.removeEventListener("turbo:before-render", this.handleBeforeRender)
        document.removeEventListener("turbo:render", this.handleRender)
        document.removeEventListener("turbo:frame-render", this.handleFrameRender)
        document.removeEventListener("turbo:before-fetch-response", this.handleBeforeFetchResponse)
        document.removeEventListener("turbo:submit-end", this.handleSubmitEnd)
    }

    handleBeforeFetchRequest(event) {
        if (event.detail.fetchOptions.headers['Turbo-Frame'] &&
            event.target.hasAttribute('data-no-instant')) {
            this.log('Ignoring fetch for data-no-instant link')
            return
        }

        this.log('Turbo fetch request started, activating spinner')
        this.activateSpinner()
    }

    handleFetchRequestError(event) {
        this.log('Turbo fetch request error, deactivating spinner')
        this.deactivateSpinner()
    }

    handleBeforeRender(event) {
        this.log('Turbo before render, preparing to deactivate spinner')
    }

    handleRender(event) {
        this.log('Turbo render complete, deactivating spinner')
        this.deactivateSpinnerWithDelay()
    }

    handleFrameRender(event) {
        this.log('Turbo frame render complete, deactivating spinner')
        this.deactivateSpinnerWithDelay()
    }

    handleBeforeFetchResponse(event) {
        this.log('Turbo before fetch response, preparing to deactivate spinner')
        if (event.detail.fetchResponse.succeeded) {
            this.deactivateSpinnerWithDelay()
        } else {
            this.log('Fetch response failed, deactivating spinner immediately')
            this.deactivateSpinner()
        }
    }

    handleSubmitEnd(event) {
        this.log('Turbo submit end, deactivating spinner')
        this.deactivateSpinner()
    }

    activateSpinner() {
        this.log('activateSpinner called')
        const spinnerWrapper = this.getSpinnerElement()
        if (spinnerWrapper) {
            const atomSpinner = spinnerWrapper.querySelector('.atom-spinner')
            if (atomSpinner) {
                atomSpinner.classList.add('active')
                this.log("Spinner activated")
            } else {
                this.log("'.atom-spinner' element not found inside the spinner wrapper", "error")
            }
        } else {
            this.log(`Spinner wrapper with id '${this.spinnerIdValue}' not found`, "error")
        }
    }

    deactivateSpinner() {
        this.log('deactivateSpinner called')
        const spinnerWrapper = this.getSpinnerElement()
        if (spinnerWrapper) {
            const atomSpinner = spinnerWrapper.querySelector('.atom-spinner')
            if (atomSpinner) {
                atomSpinner.classList.remove('active')
                this.log("Spinner deactivated")
            } else {
                this.log("'.atom-spinner' element not found inside the spinner wrapper", "error")
            }
        } else {
            this.log(`Spinner wrapper with id '${this.spinnerIdValue}' not found`, "error")
        }
    }

    deactivateSpinnerWithDelay() {
        this.log(`Deactivating spinner with delay of ${this.deactivateDelayValue}ms`)
        clearTimeout(this.deactivateTimeout) // Clear any existing timeout
        this.deactivateTimeout = setTimeout(() => {
            this.deactivateSpinner()
        }, this.deactivateDelayValue)
    }

    getSpinnerElement() {
        if (!this.spinnerIdValue) {
            this.log("Spinner ID is not set", "error")
            return null
        }
        const spinner = document.getElementById(this.spinnerIdValue)
        if (!spinner) {
            this.log(`Spinner element with id '${this.spinnerIdValue}' not found in the DOM`, "error")
        }
        return spinner
    }
}