import BaseController from "./utils/base_controller"

export default class extends BaseController {
    connect() {
        super.connect()

        this.boundHandleMouseOver = this.handleMouseOver.bind(this)
        this.boundHandleMouseOut = this.handleMouseOut.bind(this)

        this.element.addEventListener('mouseover', this.boundHandleMouseOver)
        this.element.addEventListener('mouseout', this.boundHandleMouseOut)

        this.log(`Initial z-index: ${this.element.style.zIndex || 'not set'}`, "debug")
    }

    disconnect() {
        // Remove event listeners to prevent memory leaks
        this.element.removeEventListener('mouseover', this.boundHandleMouseOver)
        this.element.removeEventListener('mouseout', this.boundHandleMouseOut)

        super.disconnect()
    }

    handleMouseOver() {
        this.element.style.zIndex = '2' // Bring the hovered card to top
        this.log("Mouse over: z-index set to 2", "debug")
    }

    handleMouseOut() {
        this.element.style.zIndex = '0' // Reset z-index when hover ends
        this.log("Mouse out: z-index reset to 0", "debug")
    }
}