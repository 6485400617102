import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["editButton", "saveButton", "viewMode", "editMode", "form"]
    static values = { editState: Boolean }

    connect() {
        const storedState = localStorage.getItem('formEditState')
        if (storedState !== null) {
            this.editStateValue = storedState === 'true'
            localStorage.removeItem('formEditState')
        } else {
            this.editStateValue = this.element.dataset.formEditStateEditStateValue === 'true'
        }
        this.updateUI()
        if (this.hasFormTarget) {
            this.formTarget.addEventListener("keypress", this.preventEnterSubmit.bind(this))
        }
    }

    disconnect() {
        if (this.hasFormTarget) {
            this.formTarget.removeEventListener("keypress", this.preventEnterSubmit.bind(this))
        }
    }

    toggleEditMode(event) {
        event.preventDefault()
        if (this.editStateValue) {
            this.cancelEdit()
        } else {
            this.editStateValue = true
            this.updateUI()
        }
    }

    updateUI() {
        this.viewModeTargets.forEach(el => el.classList.toggle("hidden", this.editStateValue))
        this.editModeTargets.forEach(el => el.classList.toggle("hidden", !this.editStateValue))
        this.saveButtonTarget.classList.toggle("hidden", !this.editStateValue)

        // Update edit button state and text
        this.editButtonTarget.dataset.state = this.editStateValue ? "active" : "inactive"
        this.editButtonTarget.textContent = this.editStateValue ? "Cancel" : "Edit"
    }
    handleSubmit(event) {
        const response = event.detail.fetchResponse
        const status = response.response.status

        if (status === 422) {
            // Store edit state before Turbo Stream refresh
            localStorage.setItem('formEditState', 'true')
            this.editStateValue = true
        } else if (status === 200) {
            localStorage.removeItem('formEditState')
            this.editStateValue = false
        }

        this.updateUI()
    }

    preventEnterSubmit(event) {
        if (event.key === "Enter" &&
            event.target.tagName !== "TEXTAREA" &&
            !event.target.closest('trix-editor')) {
            event.preventDefault()
        }
    }

    cancelEdit() {
        if (this.hasFormTarget) {
            const cancelInput = document.createElement('input')
            cancelInput.type = 'hidden'
            cancelInput.name = 'cancel'
            cancelInput.value = 'true'
            this.formTarget.appendChild(cancelInput)
            this.formTarget.requestSubmit()
            this.formTarget.removeChild(cancelInput)
        }
    }

    handleFileSelected(event) {
        const file = event.target.files[0];
        if (file) {
            const maxImageSize = 3 * 1024 * 1024;  // 3MB
            const maxSoundSize = 30 * 1024 * 1024; // 30MB
            const acceptableImageTypes = ["image/jpeg", "image/png", "image/svg+xml"];
            const acceptableSoundTypes = ["audio/mp3", "audio/mpeg"];

            const isImage = file.type.startsWith('image/');
            const isSoundFile = file.type.startsWith('audio/');

            // Check file type
            if (isImage && !acceptableImageTypes.includes(file.type)) {
                event.target.value = '';
                alert('Invalid image type. Please upload a JPEG, PNG or SVG file.');
                return;
            }

            if (isSoundFile && !acceptableSoundTypes.includes(file.type)) {
                event.target.value = '';
                alert('Invalid audio type. Please upload an MP3 file.');
                return;
            }

            // Check file size
            const maxSize = isImage ? maxImageSize : maxSoundSize;
            if (file.size > maxSize) {
                event.target.value = '';
                const sizeInMB = maxSize / (1024 * 1024);
                const fileType = isImage ? 'Image' : 'Sound';
                alert(`${fileType} file is too large. Maximum size is ${sizeInMB}MB`);
                return;
            }

            console.log(`File selected: ${file.name} (${file.type})`);
        }
    }
}