import BaseMediaPlayerController from "./base_media_player_controller"

export default class NonLoopingPlayerController extends BaseMediaPlayerController {
    connect() {
        super.connect()
        this.log("NonLoopingPlayer connected", "info")
    }

    onAudioLoaded() {
        super.onAudioLoaded()
        // Any additional logic specific to non-looping player can be added here
    }

    // Override the plyrOptions getter if you need different options
    get plyrOptions() {
        return {
            ...super.plyrOptions,
            speed: {
                default: 1,
                options: [0.7, 0.8, 0.9, 1, 1.1, 1.2]
            }
        }
    }
}