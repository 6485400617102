// event_loggers.js

const eventLoggers = {
    "turbo:visit": () => console.log("visit"),
    "turbo:before-visit": () => console.log("before-visit"),
    "turbo:fetch-response": () => console.log("fetch-response"),
    "turbo:before-fetch-response": (event) => console.log("Before Fetch Response", event),
    "turbo:submit-end": (event) => console.log("turbo:submit-end", event),
    "turbo:frame-render": (event) => console.log("turbo:frame-render", event),
    "turbo:stream-render": (event) => console.log("turbo:stream-render", event),
    "turbo:frame-load": (event) => console.log("Frame has loaded", event)
};

export function handleTurboEvent(event) {
    const logger = eventLoggers[event.type];
    if (logger) {
        logger(event);
    }
}