// app/javascript/controllers/tinymce_controller.js

import { Controller } from "@hotwired/stimulus"
import debounce from 'lodash/debounce'

export default class extends Controller {
    connect() {
        this.deletedImages = new Set()
        this.initialContent = ''
        this.initializeTinyMCE()
        this.element.closest('form').addEventListener('submit', this.handleFormSubmit.bind(this))
    }

    disconnect() {
        this.destroyTinyMCE()
    }

    initializeTinyMCE() {
        if (typeof tinyMCE !== 'undefined') {
            tinyMCE.init({
                selector: `#${this.element.id}`,
                license_key: 'gpl',
                height: 400,
                menubar: true,
                plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code help wordcount',
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image | code | help',
                images_upload_url: '/tinymce_assets',
                automatic_uploads: true,
                content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                images_upload_handler: this.handleImageUpload.bind(this),

                setup: (editor) => {
                    editor.on('LoadContent', (e) => {
                        this.initialContent = editor.getContent();
                    });

                    // Immediate deletion on remove
                    editor.on('NodeChange', (e) => {
                        if (e.element.nodeName === 'IMG' && e.type === 'remove') {
                            const imageUrl = e.element.src;
                            this.deleteImage(imageUrl);
                        }
                    });

                    // On blur check
                    editor.on('blur', () => {
                        const currentContent = editor.getContent();
                        this.checkForDeletedImages(this.initialContent, currentContent);
                        this.initialContent = currentContent;
                    });

                    // Keep the existing Change event for additional safety
                    editor.on('Change', debounce((e) => {
                        const newContent = editor.getContent();
                        this.checkForDeletedImages(this.initialContent, newContent);
                        this.initialContent = newContent;
                    }, 500)); // Debounce for 500ms
                }
            });
        } else {
            console.error('TinyMCE is not loaded. Make sure you have included it correctly.');
        }
    }

    handleImageUpload(blobInfo, progress) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/tinymce_assets');

            // Include CSRF token
            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            xhr.setRequestHeader('X-CSRF-Token', csrfToken);

            xhr.onload = () => {
                if (xhr.status === 403) {
                    reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
                    return;
                }

                if (xhr.status < 200 || xhr.status >= 300) {
                    reject('HTTP Error: ' + xhr.status);
                    return;
                }

                const json = JSON.parse(xhr.responseText);

                if (!json || typeof json.location != 'string') {
                    reject('Invalid JSON: ' + xhr.responseText);
                    return;
                }

                resolve(json.location);
            };

            xhr.onerror = () => {
                reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
            };

            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());

            xhr.send(formData);
        });
    }

    checkForDeletedImages(oldContent, newContent) {
        const oldImages = this.extractImageUrls(oldContent);
        const newImages = this.extractImageUrls(newContent);

        console.log("oldImages", oldImages);
        console.log("newImages", newImages);

        oldImages.forEach(imageUrl => {
            if (!newImages.includes(imageUrl)) {
                this.deleteImage(imageUrl)
            }
        });
    }

    extractImageUrls(content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const images = doc.getElementsByTagName('img');
        return Array.from(images).map(img => img.src);
    }

    deleteImage(imageUrl) {
        const xhr = new XMLHttpRequest();
        xhr.open('DELETE', '/tinymce_assets');
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        xhr.setRequestHeader('X-CSRF-Token', csrfToken);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = () => {
            if (xhr.status === 200) {
                console.log('Image deleted successfully');
                this.deletedImages.delete(imageUrl);
            } else {
                console.error('Failed to delete image');
            }
        };
        xhr.send(JSON.stringify({ image_url: imageUrl }));
    }

    handleFormSubmit(event) {
        // Pre-submit check
        const editor = tinymce.get(this.element.id);
        const currentContent = editor.getContent();
        this.checkForDeletedImages(this.initialContent, currentContent);

        // Delete any remaining images
        this.deletedImages.forEach(imageUrl => {
            this.deleteImage(imageUrl);
        });
    }

    destroyTinyMCE() {
        if (typeof tinyMCE !== 'undefined') {
            tinyMCE.remove(`#${this.element.id}`);
        }
    }
}