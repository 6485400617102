// Flash Messages Controller
//
// This controller manages the display of flash messages in the application.
// It listens for Turbo events to clear existing messages and display new ones.

import BaseController from "./utils/base_controller"

export default class extends BaseController {
    connect() {
        super.connect()
        this.listen()
    }

    disconnect() {
        this.removeEventListeners()
        super.disconnect()
    }

    listen() {
        this.boundClearMessages = this.clearMessages.bind(this)
        this.boundHandleSubmitEnd = this.handleSubmitEnd.bind(this)

        document.addEventListener("turbo:render", this.boundClearMessages)
        document.addEventListener("turbo:submit-end", this.boundHandleSubmitEnd)

        this.log("Event listeners added", "debug")
    }

    removeEventListeners() {
        document.removeEventListener("turbo:render", this.boundClearMessages)
        document.removeEventListener("turbo:submit-end", this.boundHandleSubmitEnd)

        this.log("Event listeners removed", "debug")
    }

    clearMessages() {
        this.element.innerHTML = ""
        this.log("Flash messages cleared", "debug")
    }

    handleSubmitEnd(event) {
        this.log("turbo:submit-end event received", "debug")
        this.log(`Event detail: ${JSON.stringify(event.detail)}`, "debug")

        const flash = event.detail.fetchResponse.response.headers.get("Flash")

        if (flash) {
            this.element.innerHTML = flash
            this.log("New flash message displayed", "info")
        } else {
            this.log("No flash message in response", "debug")
        }
    }
}