// Author: Colin McGowan
// Website: atomisedguitar.com
// Copyright © 2024 Colin McGowan. All rights reserved.
//
// This file is part of the Atomised Guitar web application.
// Unauthorized copying, modification, distribution, or use of this file,
// via any medium, is strictly prohibited.
//
// ReviewController
//
// This Stimulus controller manages the visibility of question and answer
// elements in the review functionality. It allows for hiding the question
// and revealing the answer.

import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["question", "answer"]

    // Called when the controller is connected to the DOM
    // Shows the question and hides the answer
    connect() {
        this.questionTarget.classList.remove("hidden")
        this.answerTarget.classList.add("hidden")
    }

    // Toggles visibility: hides the question and reveals the answer
    revealAnswer() {
        this.questionTarget.classList.add("hidden")
        this.answerTarget.classList.remove("hidden")
    }
}