document.addEventListener('turbo:load', (event) => {
    const timer = document.getElementById('timer');
    const startButton = document.getElementById('start');
    const stopButton = document.getElementById('stop');
    const resetButton = document.getElementById('reset');

    let startTime = 0;
    let elapsedTime = 0;
    let timerInterval;

    const timerDisplay = document.getElementById('timer-display');

    function toggleTimer() {
        if (timerInterval) {
            // If the timer is running, pause it
            clearInterval(timerInterval);
            timerInterval = null;
            startButton.innerHTML = getButtonHTML(startButton.dataset.playSrc);
        } else {
            // If the timer is paused, start it
            startTime = Date.now() - elapsedTime;
            timerInterval = setInterval(() => {
                elapsedTime = Date.now() - startTime;
                timerDisplay.textContent = formatTimer(elapsedTime);
            }, 1000);
            startButton.innerHTML = getButtonHTML(startButton.dataset.pauseSrc);

        }
    }

    function getButtonHTML(src) {
        return `<img src="${src}" id="pauseplaylogo" height="32" width="32" alt="Start/Stop">`;
    }

    function resetTimer() {
        clearInterval(timerInterval);
        timerInterval = null;
        elapsedTime = 0;
        timerDisplay.textContent = "00:00:00";
        startButton.innerHTML = getButtonHTML(startButton.dataset.playSrc);
    }

    function formatTimer(elapsedTime) {
        const hours = Math.floor(elapsedTime / (1000 * 60 * 60));
        const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

        return (
            (hours ? (hours > 9 ? hours : "0" + hours) : "00") +
            ":" +
            (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") +
            ":" +
            (seconds ? (seconds > 9 ? seconds : "0" + seconds) : "00")
        )
    }

    if (startButton)
        startButton.addEventListener("click", toggleTimer);
    if (resetButton)
        resetButton.addEventListener("click", resetTimer);

});