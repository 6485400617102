import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["notes", "button", "contentContainer"]

    connect() {
        this.updateButtonText()
    }

    toggle(event) {
        event.preventDefault()
        this.notesTarget.classList.toggle("w-0")
        this.notesTarget.classList.toggle("md:w-0")
        this.notesTarget.classList.toggle("w-full")
        this.notesTarget.classList.toggle("md:w-1/3")
        this.contentContainerTarget.classList.toggle("w-full")
        this.contentContainerTarget.classList.toggle("md:w-2/3")
        this.buttonTarget.classList.toggle("active")
        this.updateButtonText()
    }

    updateButtonText() {
        const isHidden = this.notesTarget.classList.contains("w-0")
        this.buttonTarget.textContent = isHidden ? "Show Notes" : "Hide Notes"
    }
}