import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["practiceButtons"]
    static values = {
        delay: { type: Number, default: 2000 } // Default delay of 2 seconds
    }

    connect() {
        this.practiceButtonsTarget.classList.add("hidden")
        document.addEventListener('plyr:play', this.onPlay.bind(this))
    }

    disconnect() {
        document.removeEventListener('plyr:play', this.onPlay.bind(this))
    }

    onPlay() {
        // Clear any existing timeout to prevent multiple reveals
        if (this.revealTimeout) {
            clearTimeout(this.revealTimeout)
        }

        // Set a new timeout
        this.revealTimeout = setTimeout(() => {
            this.revealButtons()
        }, this.delayValue)
    }

    revealButtons() {
        this.practiceButtonsTarget.classList.remove("hidden")
    }
}