// app/javascript/controllers/badge_updates_controller.js
import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
    static targets = ["todayBadge", "upcomingBadge"]
    static values = {
        tabType: String,
        initialToday: Number,
        initialUpcoming: Number
    }

    connect() {
        this.updateBadge(this.todayBadgeTarget, this.initialTodayValue)
        this.updateBadge(this.upcomingBadgeTarget, this.initialUpcomingValue)

        this.channel = consumer.subscriptions.create("BadgeUpdatesChannel", {
            received: this.handleUpdate.bind(this)
        })
    }

    disconnect() {
        if (this.channel) {
            this.channel.unsubscribe()
        }
    }

    handleUpdate(data) {
        const tabData = data[this.tabTypeValue]
        if (tabData) {
            this.updateBadge(this.todayBadgeTarget, tabData.today)
            this.updateBadge(this.upcomingBadgeTarget, tabData.upcoming)
        }
    }

    updateBadge(badgeElement, count) {
        badgeElement.textContent = count
        badgeElement.classList.toggle('hidden', count === 0)
    }
}