import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["textArea", "button"]

    connect() {
        super.connect()
        if (this.hasTextAreaTarget) {
            this.boundKeyUpHandler = this.handleKeyUp.bind(this)
            this.textAreaTarget.addEventListener("keyup", this.boundKeyUpHandler)
        } else {
            this.log("TextArea target not found. Submit on Enter functionality disabled.", "warn")
        }
    }

    disconnect() {
        if (this.hasTextAreaTarget) {
            this.textAreaTarget.removeEventListener("keyup", this.boundKeyUpHandler)
        }
        super.disconnect()
    }

    handleKeyUp(event) {
        if (event.key === "Enter" && !event.shiftKey) {
            this.log("Enter key pressed without Shift", "debug")
            event.preventDefault()
            this.submitForm()
        }
    }

    submitForm() {
        this.log("Initiating form submission", "debug")
        if (this.hasButtonTarget) {
            this.buttonTarget.click()
            this.log("Form submitted via button click", "info")
        } else {
            this.log("Button target not found, form submission failed", "error")
        }
    }
}