import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        createPath: String,
        destroyPath: String
    }

    toggle(event) {
        const form = event.target.closest("form")
        const formData = new FormData(form)

        const csrfToken = document.querySelector("meta[name='csrf-token']").content
        const path = event.target.checked ? this.createPathValue : this.destroyPathValue

        const fetchOptions = {
            method: event.target.checked ? 'POST' : 'DELETE',
            body: formData,
            headers: {
                "X-CSRF-Token": csrfToken,
                "Accept": "text/vnd.turbo-stream.html"
            },
            credentials: 'same-origin'
        }

        fetch(path, fetchOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
            })
            .catch(error => {
                console.error('Error:', error)
                // Revert checkbox state on error
                event.target.checked = !event.target.checked
            })
    }
}
