import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = [
        "container",
        "notes",
        "video",
        "metronome",
        "timer",
        "notesButton",
        "videoButton",
        "metronomeButton",
        "timerButton",
        "noneButton",
        "sideBar",
        "image"
    ]

    connect() {
        this.hideAll()
        this.updateButtonStates("none")
    }

    toggleNone(event) {
        event.preventDefault()
        this.hideAll()
        this.updateButtonStates("none")
    }

    toggleNotes(event) {
        event.preventDefault()
        this.hideAll()
        this.containerTarget.classList.add("notes-visible")
        this.updateButtonStates("notes")
    }

    toggleVideo(event) {
        event.preventDefault()
        this.hideAll()
        this.containerTarget.classList.add("video-visible")
        this.updateButtonStates("video")
    }

    toggleMetronome(event) {
        event.preventDefault()
        this.hideAll()
        this.containerTarget.classList.add("metronome-visible")
        this.updateButtonStates("metronome")
    }

    toggleTimer(event) {
        event.preventDefault()
        this.hideAll()
        this.containerTarget.classList.add("timer-visible")
        this.updateButtonStates("timer")
    }

    hideAll() {
        this.containerTarget.classList.remove(
            "notes-visible",
            "video-visible",
            "metronome-visible",
            "timer-visible"
        )
    }

    updateButtonStates(activeView) {
        const buttons = {
            none: this.noneButtonTarget,
            notes: this.notesButtonTarget,
            video: this.videoButtonTarget,
            metronome: this.metronomeButtonTarget,
            timer: this.timerButtonTarget
        }

        Object.entries(buttons).forEach(([view, button]) => {
            button.dataset.state = view === activeView ? "active" : "inactive"
        })
    }
}