/**
 * GuitarNoteTunerController
 *
 * This Stimulus controller manages a guitar note tuner interface.
 * It handles playing and stopping individual guitar note sounds for tuning purposes.
 *
 * Key features:
 * - Plays individual guitar note audio files
 * - Manages play/stop states with visual feedback (image swapping)
 * - Allows switching between different guitar notes
 * - Ensures only one note plays at a time
 *
 * Usage:
 * Add data-controller="guitar-note-tuner" to the parent element and
 * data-action="click->guitar-note-tuner#play" to each note button.
 * Include data-guitar-note-tuner-target="button" on each button element.
 */
import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = ["button"]
    static values = {
        currentlyPlaying: String
    }

    connect() {
        super.connect()
        this.audio = new Audio()
        this.log("Audio object initialized", "debug")
    }

    disconnect() {
        this.stopPlaying()
        super.disconnect()
    }

    play(event) {
        const button = event.currentTarget
        const sound = button.dataset.sound
        const notPlayingImage = button.dataset.notPlayingImage
        const playingImage = button.dataset.playingImage

        this.log(`Play button clicked for sound: ${sound}`, "debug")

        if (this.currentlyPlayingValue === sound) {
            this.log(`Stopping currently playing sound: ${sound}`, "debug")
            this.stopPlaying()
        } else {
            this.log(`Starting new sound: ${sound}`, "debug")
            this.stopPlaying()
            this.startPlaying(sound, button, playingImage)
        }
    }

    startPlaying(sound, button, playingImage) {
        this.resetAllButtons()
        this.audio.src = `/sounds/tuner/${sound}.mp3`
        this.log(`Set audio source to: ${this.audio.src}`, "debug")

        this.audio.play().then(() => {
            this.log(`Started playing sound: ${sound}`, "info")
        }).catch(error => {
            this.log(`Error playing sound ${sound}: ${error}`, "error")
        })

        button.querySelector('img').src = playingImage
        this.log(`Updated button image to playing state`, "debug")

        this.currentlyPlayingValue = sound
        this.log(`Updated currently playing value to: ${sound}`, "debug")
    }

    stopPlaying() {
        if (this.currentlyPlayingValue) {
            this.log(`Stopping sound: ${this.currentlyPlayingValue}`, "info")
            this.audio.pause()
            this.audio.currentTime = 0
            this.resetAllButtons()
            this.currentlyPlayingValue = ""
            this.log("Reset currently playing value", "debug")
        } else {
            this.log("No sound currently playing to stop", "debug")
        }
    }

    resetAllButtons() {
        this.log("Resetting all buttons to not playing state", "debug")
        this.buttonTargets.forEach(btn => {
            btn.querySelector('img').src = btn.dataset.notPlayingImage
        })
    }
}