import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "bpmDropdown",
        "bpmInput",
        "playButton",
        "volumeSlider",
        "volumeIndicator",
        "startBpm",
        "endBpm",
        "increment",
        "intervalSelect",
        "standardModeBtn",
        "accelerationModeBtn",
        "standardControls",
        "accelerationControls",
        "currentBpm"
    ]

    static values = {
        playingImageUrl: String,
        pausedImageUrl: String,
        bpm: { type: Number, default: 120 },
        volume: { type: Number, default: 0.5 },
        isPlaying: Boolean,
        isAccelerationMode: Boolean,
        startBpm: { type: Number, default: 60 },
        endBpm: { type: Number, default: 120 },
        increment: { type: Number, default: 5 },
        interval: { type: Number, default: 30 },
        loadPath: String,
        savePath: String,
        atomId: String
    }

    connect() {
        this.audioContext = null
        this.notesInQueue = []
        this.currentBeatInBar = 0
        this.beatsPerBar = 1
        this.lookahead = 25
        this.scheduleAheadTime = 0.1
        this.nextNoteTime = 0.0
        this.intervalId = null
        this.accelerationIntervalId = null

        this.initializeDefaultValues()
        this.loadSettings()
        this.updateUI()
    }

    initializeDefaultValues() {
        this.bpmValue = 120
        this.volumeValue = 0.5
        this.isAccelerationModeValue = false
        this.startBpmValue = 60
        this.endBpmValue = 120
        this.incrementValue = 5
        this.intervalValue = 30
    }

    async loadSettings() {
        if (this.hasLoadPathValue) {
            try {
                const response = await fetch(this.loadPathValue)
                if (response.ok) {
                    const settings = await response.json()
                    console.log('Applying  API settings:', settings);
                    this.applySettings(settings)
                    return
                }
            } catch (error) {
                console.error('Error loading API settings:', error)
            }
        }

        const savedSettings = JSON.parse(localStorage.getItem('metronomeSettings') || '{}')
        console.log('Applying local storagt settings:', savedSettings);
        if (Object.keys(savedSettings).length) {
            this.applySettings(savedSettings)
        }
    }

    applySettings(settings) {
        if (settings.bpm) this.bpmValue = parseInt(settings.bpm)
        if (settings.volume) this.volumeValue = parseFloat(settings.volume)
        if (typeof settings.isAccelerationMode === 'boolean') {
            this.isAccelerationModeValue = settings.isAccelerationMode
        }
        if (settings.startBpm) this.startBpmValue = parseInt(settings.startBpm)
        if (settings.endBpm) this.endBpmValue = parseInt(settings.endBpm)
        if (settings.increment) this.incrementValue = parseInt(settings.increment)
        if (settings.interval) this.intervalValue = parseInt(settings.interval)

        this.updateUI()
    }

    async saveSettings() {
        const settings = {
            date: new Date().toDateString(),
            bpm: this.bpmValue,
            volume: this.volumeValue,
            isAccelerationMode: this.isAccelerationModeValue,
            startBpm: this.startBpmValue,
            endBpm: this.endBpmValue,
            increment: this.incrementValue,
            interval: this.intervalValue
        }

        console.log('savePath:', this.savePathValue)

        if (this.hasSavePathValue) {
            try {
                console.log('Trying to save to API settings:')
                const response = await fetch(this.savePathValue, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                    },
                    body: JSON.stringify({ metronome_settings: settings })
                })

                if (!response.ok) throw new Error('Failed to save settings')
            } catch (error) {
                console.error('Error saving API settings:', error)
                localStorage.setItem('metronomeSettings', JSON.stringify(settings))
            }
        } else {
            localStorage.setItem('metronomeSettings', JSON.stringify(settings))
        }
    }

    updateUI() {
        this.updateVolumeIndicator()
        this.updateBpmDisplays()

        if (this.isAccelerationModeValue) {
            this.standardControlsTarget.classList.add('hidden')
            this.accelerationControlsTarget.classList.remove('hidden')
            this.standardModeBtnTarget.dataset.state = 'inactive'
            this.accelerationModeBtnTarget.dataset.state = 'active'
        } else {
            this.standardControlsTarget.classList.remove('hidden')
            this.accelerationControlsTarget.classList.add('hidden')
            this.standardModeBtnTarget.dataset.state = 'active'
            this.accelerationModeBtnTarget.dataset.state = 'inactive'
        }

        this.volumeSliderTarget.value = this.volumeValue
        this.bpmDropdownTarget.value = this.bpmValue.toString()
        this.bpmInputTarget.value = this.bpmValue.toString()
        this.startBpmTarget.value = this.startBpmValue.toString()
        this.endBpmTarget.value = this.endBpmValue.toString()
        this.incrementTarget.value = this.incrementValue.toString()
        this.intervalSelectTarget.value = this.intervalValue.toString()

        this.highlightActiveBpmControl(this.bpmDropdownTarget)
    }

    updateVolumeIndicator() {
        const volumePercentage = Math.round(this.volumeValue * 100)
        this.volumeIndicatorTarget.textContent = `${volumePercentage}%`
    }

    updateBpmDisplays() {
        this.bpmDropdownTarget.value = this.bpmValue.toString()
        this.bpmInputTarget.value = this.bpmValue.toString()
        this.updateCurrentBpmDisplay()
    }

    updateCurrentBpmDisplay() {
        this.currentBpmTarget.textContent = `${this.bpmValue} BPM`
    }

    highlightActiveBpmControl(activeElement) {
        this.bpmDropdownTarget.classList.remove("ring-2", "ring-blue-500")
        this.bpmInputTarget.classList.remove("ring-2", "ring-blue-500")
        activeElement.classList.add("ring-2", "ring-blue-500")
    }

    changeBpmFromDropdown() {
        const value = parseInt(this.bpmDropdownTarget.value)
        this.bpmValue = value
        this.bpmInputTarget.value = value
        this.updateCurrentBpmDisplay()
        this.highlightActiveBpmControl(this.bpmDropdownTarget)
        this.saveSettings()
    }

    changeBpmFromInput() {
        const value = parseInt(this.bpmInputTarget.value)
        if (value >= 40 && value <= 208) {
            this.bpmValue = value
            this.bpmDropdownTarget.value = value
            this.updateCurrentBpmDisplay()
            this.highlightActiveBpmControl(this.bpmInputTarget)
            this.saveSettings()
        }
    }

    changeVolume() {
        this.volumeValue = parseFloat(this.volumeSliderTarget.value)
        this.updateVolumeIndicator()
        this.saveSettings()
    }

    updateAccelerationSettings(event) {
        const target = event.currentTarget
        const value = parseInt(target.value)

        switch(target.getAttribute('data-guitar-tools--metronome-target')) {
            case 'startBpm':
                this.startBpmValue = value
                break
            case 'endBpm':
                this.endBpmValue = value
                break
            case 'increment':
                this.incrementValue = value
                break
            case 'intervalSelect':
                this.intervalValue = value
                break
        }

        this.saveSettings()
    }

    switchToStandardMode() {
        if (this.isPlayingValue) this.stop()
        this.isAccelerationModeValue = false
        this.standardControlsTarget.classList.remove('hidden')
        this.accelerationControlsTarget.classList.add('hidden')
        this.standardModeBtnTarget.dataset.state = 'active'
        this.accelerationModeBtnTarget.dataset.state = 'inactive'
        this.saveSettings()
    }

    switchToAccelerationMode() {
        if (this.isPlayingValue) this.stop()
        this.isAccelerationModeValue = true
        this.standardControlsTarget.classList.add('hidden')
        this.accelerationControlsTarget.classList.remove('hidden')
        this.standardModeBtnTarget.dataset.state = 'inactive'
        this.accelerationModeBtnTarget.dataset.state = 'active'
        this.saveSettings()
    }

    togglePlay() {
        if (this.isPlayingValue) {
            this.stop()
        } else {
            this.start()
        }
    }

    start() {
        if (this.isPlayingValue) return

        if (!this.audioContext) {
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
        }

        this.isPlayingValue = true
        this.currentBeatInBar = 0
        this.nextNoteTime = this.audioContext.currentTime + 0.05

        if (this.isAccelerationModeValue) {
            this.startAcceleration()
        } else {
            this.intervalId = setInterval(() => this.scheduler(), this.lookahead)
        }

        this.updatePlayButtonImage()
    }

    stop() {
        this.isPlayingValue = false
        if (this.intervalId) {
            clearInterval(this.intervalId)
            this.intervalId = null
        }
        if (this.accelerationIntervalId) {
            clearInterval(this.accelerationIntervalId)
            this.accelerationIntervalId = null
        }
        this.updatePlayButtonImage()
    }

    updatePlayButtonImage() {
        this.playButtonTarget.src = this.isPlayingValue ?
            this.playingImageUrlValue :
            this.pausedImageUrlValue
    }

    nextNote() {
        const secondsPerBeat = 60.0 / this.bpmValue
        this.nextNoteTime += secondsPerBeat

        this.currentBeatInBar++
        if (this.currentBeatInBar === this.beatsPerBar) {
            this.currentBeatInBar = 0
        }
    }

    scheduleNote(beatNumber, time) {
        this.notesInQueue.push({ note: beatNumber, time: time })

        const osc = this.audioContext.createOscillator()
        const envelope = this.audioContext.createGain()

        osc.frequency.value = (beatNumber % this.beatsPerBar === 0) ? 1000 : 800
        envelope.gain.value = this.volumeValue

        if (this.volumeValue !== 0) {
            envelope.gain.exponentialRampToValueAtTime(this.volumeValue, time + 0.001)
            envelope.gain.exponentialRampToValueAtTime(0.001, time + 0.02)
        }

        osc.connect(envelope)
        envelope.connect(this.audioContext.destination)

        osc.start(time)
        osc.stop(time + 0.03)
    }

    scheduler() {
        while (this.nextNoteTime < this.audioContext.currentTime + this.scheduleAheadTime) {
            this.scheduleNote(this.currentBeatInBar, this.nextNoteTime)
            this.nextNote()
        }
    }

    startAcceleration() {
        if (this.startBpmValue >= this.endBpmValue) {
            alert("End BPM must be greater than Start BPM")
            this.stop()
            return
        }

        this.bpmValue = this.startBpmValue
        this.updateBpmDisplays()

        this.intervalId = setInterval(() => this.scheduler(), this.lookahead)
        this.accelerationIntervalId = setInterval(() => {
            if (this.bpmValue < this.endBpmValue) {
                this.bpmValue = Math.min(this.bpmValue + this.incrementValue, this.endBpmValue)
                this.updateBpmDisplays()
            }
        }, this.intervalValue * 1000)
    }
}