// Image Refresher Controller
//
// This controller manages the refreshing of images based on changes in the DOM.
// It observes a specified upload section and triggers a refresh when the section is hidden.
// The controller uses MutationObserver to watch for changes and Turbo to handle the refresh.

import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["uploadSection", "displayFrame"]
    static values = {
        refreshUrl: String
    }

    connect() {
        super.connect()
        this.log(`Refresh URL: ${this.refreshUrlValue}`, "debug")
        this.observeUploadSection()
    }

    disconnect() {
        if (this.observer) {
            this.observer.disconnect()
            this.log("MutationObserver disconnected", "debug")
        }
        super.disconnect()
    }

    observeUploadSection() {
        this.log("Setting up MutationObserver for upload section", "debug")
        this.observer = new MutationObserver(this.handleMutation.bind(this))
        this.observer.observe(this.uploadSectionTarget, { attributes: true, attributeFilter: ['class'] })
        this.log("MutationObserver set up successfully", "debug")
    }

    handleMutation(mutations) {
        for (const mutation of mutations) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                this.log("Class attribute changed on upload section", "debug")
                if (this.uploadSectionTarget.classList.contains('hidden')) {
                    this.log("Upload section is now hidden, refreshing image", "info")
                    this.refreshImage()
                } else {
                    this.log("Upload section is now visible", "info")
                }
            }
        }
    }

    refreshImage() {
        const refreshUrl = this.displayFrameTarget.dataset.refreshUrl

        if (refreshUrl) {
            this.log(`Attempting to refresh image with URL: ${refreshUrl}`, "debug")
            try {
                Turbo.visit(refreshUrl, { frame: this.displayFrameTarget.id, action: "replace" })
                this.log("Turbo.visit called successfully", "debug")
            } catch (error) {
                this.log(`Error during reload: ${error}`, "error")
            }
        } else {
            this.log("No refresh URL found", "error")
        }

        this.checkFrameContentAfterReload()
    }

    checkFrameContentAfterReload() {
        setTimeout(() => {
            this.log("Checking frame content after reload attempt", "debug")
            this.log(`Frame content: ${this.displayFrameTarget.innerHTML.substring(0, 100)}...`, "debug")
        }, 1000)
    }
}