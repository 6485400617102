// Submit on Change Controller
//
// This controller automatically submits a form when a change event occurs on any of its fields.
// It also handles special logic for reporting period and specific date fields.

import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = ["form", "reportingPeriod", "specificDate"]

    connect() {
        super.connect()
        this.boundHandleChange = this.handleChange.bind(this)
        this.element.addEventListener("change", this.boundHandleChange)
    }

    disconnect() {
        this.element.removeEventListener("change", this.boundHandleChange)
        super.disconnect()
    }

    handleChange(event) {
        this.log(`Change event triggered on ${event.target.name || 'unnamed element'}`, "debug")

        if (this.hasReportingPeriodTarget && this.hasSpecificDateTarget &&
            event.target === this.reportingPeriodTarget) {
            this.specificDateTarget.value = ''
            this.log("Specific date value cleared due to reporting period change", "debug")
        }

        this.submitForm(event)
    }

    submitForm(event) {
        const form = this.findForm()
        if (form) {
            this.log("Form found, attempting to submit", "debug")
            if (typeof form.requestSubmit === 'function') {
                form.requestSubmit()
                this.log("Form submitted using requestSubmit()", "info")
            } else {
                form.submit()
                this.log("Form submitted using submit()", "info")
            }
        } else {
            this.log("Form not found for submit--on-change controller", "error")
        }
    }

    findForm() {
        if (this.hasFormTarget) {
            this.log("Form found via form target", "debug")
            return this.formTarget
        }
        if (this.element.tagName === 'FORM') {
            this.log("Controller element is the form", "debug")
            return this.element
        }
        const closestForm = this.element.closest('form')
        if (closestForm) {
            this.log("Form found via closest parent", "debug")
        } else {
            this.log("No form found", "warn")
        }
        return closestForm
    }
}