import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = [
        "container",
        "atomSoundHideShow",
        "atomNotesHideShow",
        "atomVideoHideShow",
        "atomSoundButton",
        "atomNotesButton",
        "atomVideoButton"
    ];

    connect() {
        super.connect()
        this.log("practice_hide_show_controller connected", "debug")
        this.initializeController()
    }

    disconnect() {
        this.log("practice_hide_show_controller disconnected", "debug")
        super.disconnect()
    }

    initializeController() {
        if (this.hasContainerTarget) {
            this.hideAllSections()
            this.deactivateAllButtons()
            this.updateContainerVisibility()
        } else {
            this.log("Container target not found. Some functionality may be limited.", "warn")
        }
    }

    toggleSection(sectionName) {
        if (!this.hasContainerTarget) {
            this.log(`Cannot toggle ${sectionName} section: container not found`, "warn")
            return
        }

        const isVisible = this.containerTarget.classList.contains(`${sectionName}-visible`);
        this.hideAllSections();
        this.deactivateAllButtons();

        if (!isVisible) {
            this.containerTarget.classList.add(`${sectionName}-visible`);
            this.activateButton(sectionName);
        }

        this.updateContainerVisibility();
    }

    atomNotesHideShow() {
        this.toggleSection("notes");
    }

    atomSoundHideShow() {
        this.toggleSection("sound");
    }

    atomVideoHideShow() {
        this.toggleSection("video");
    }

    hideAllSections() {
        if (!this.hasContainerTarget) return
        this.containerTarget.classList.remove("notes-visible", "video-visible", "sound-visible");
    }

    deactivateAllButtons() {
        ["atomSoundButton", "atomNotesButton", "atomVideoButton"].forEach(buttonName => {
            if (this.hasTarget(buttonName)) {
                this[`${buttonName}Target`].dataset.state = "inactive";
            }
        });
    }

    activateButton(sectionName) {
        const buttonName = `atom${this.capitalize(sectionName)}Button`;
        if (this.hasTarget(buttonName)) {
            this[`${buttonName}Target`].dataset.state = "active";
        }
    }

    updateContainerVisibility() {
        if (!this.hasContainerTarget) return

        const hasVisibleContent = ["notes", "video", "sound"].some(section =>
            this.containerTarget.classList.contains(`${section}-visible`)
        );

        if (hasVisibleContent) {
            this.containerTarget.classList.remove("no-content-visible");
        } else {
            this.containerTarget.classList.add("no-content-visible");
        }
    }

    hasTarget(targetName) {
        return this[`has${this.capitalize(targetName)}Target`]
    }

    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}