import BaseMediaPlayerController from "./base_media_player_controller"

export default class SmallPlayerInitialiseController extends BaseMediaPlayerController {
    static targets = ["player", "revealButton"]
    static values = { debug: Boolean }

    connect() {
        super.connect()
        this.log("SmallPlayerInitialiseController connected", "info")
        this.players = {}
        this.initializePlayers()
    }

    disconnect() {
        this.log("SmallPlayerInitialiseController disconnecting", "info")
        // Clean up all initialized players
        Object.values(this.players).forEach(player => {
            player.destroy()
        })
        this.players = {}
        super.disconnect()
    }

    initializePlayers() {
        this.log(`Found ${this.playerTargets.length} player targets`, "debug")
        this.playerTargets.forEach((playerElement, index) => {
            const playerClass = playerElement.getAttribute('data-player-class')
            this.log(`Initializing player ${index + 1} with class ${playerClass}`, "debug")
            try {
                const player = new Plyr(playerElement, this.plyrOptions)
                player.speed = 1
                this.players[playerClass] = player

                // Add event listener for play event
                player.on('play', () => this.revealAssociatedButton(playerClass))

                this.log(`Player ${index + 1} initialized successfully`, "debug")
            } catch (error) {
                this.log(`Error initializing player ${index + 1}: ${error}`, "error")
            }
        })
    }

    revealAssociatedButton(playerClass) {
        this.log(`revealAssociatedButton called with playerClass: ${playerClass}`, "debug")
        this.log(`Number of revealButton targets: ${this.revealButtonTargets.length}`, "debug")

        const buttonTarget = this.revealButtonTargets.find(button => {
            const associatedPlayer = button.getAttribute('data-associated-player')
            this.log(`Checking button with associated player: ${associatedPlayer}`, "debug")
            return associatedPlayer === playerClass
        })

        if (buttonTarget) {
            this.log(`Button target found: ${buttonTarget}`, "debug")
            buttonTarget.classList.remove('hidden')
            buttonTarget.classList.add('flex')
        } else {
            this.log("No matching button target found", "warn")
        }
    }

    get plyrOptions() {
        return {
            ...super.plyrOptions,
            controls: [
                'restart',
                'play',
                'volume',
                'settings',
            ],
            speed: {
                default: 1,
                options: [0.8, 0.85, 0.9, 1, 1.1, 1.2],
            }
        }
    }

    // Override setupAudio and setupPlyr to prevent them from running
    // as we're handling player initialization differently
    setupAudio() {}
    setupPlyr() {}
}