import BaseMediaPlayerController from "./base_media_player_controller"

export default class LoopingPlayerController extends BaseMediaPlayerController {
    static targets = [
        ...BaseMediaPlayerController.targets,
        "loopToggle", "startMinutes", "startSeconds", "endMinutes", "endSeconds",
        "progressBar", "loopIndicator", "startHandle", "endHandle"
    ]

    connect() {
        super.connect()
        this.log("LoopingPlayer connected", "info")
        this.initializeLoopValues()
        this.setupDropdowns()
        this.setupDragAndDrop()
    }

    disconnect() {
        this.removeDragAndDropListeners()
        super.disconnect()
    }

    initializeLoopValues() {
        this.loopStart = 0
        this.loopEnd = 0
        this.minLoopLength = 5 // Minimum loop length in seconds
    }

    setupEventListeners() {
        super.setupEventListeners()
        if (this.player) {
            this.player.on('ended', this.onPlayerEnded.bind(this))
        }
        if (this.audio) {
            this.audio.addEventListener('timeupdate', this.onTimeUpdate.bind(this))
        }
        this.loopToggleTarget.addEventListener('change', this.onLoopToggleChange.bind(this))
        this.startMinutesTarget.addEventListener('change', this.onStartMinutesChange.bind(this))
        this.startSecondsTarget.addEventListener('change', this.onStartSecondsChange.bind(this))
        this.endMinutesTarget.addEventListener('change', this.onEndMinutesChange.bind(this))
        this.endSecondsTarget.addEventListener('change', this.onEndSecondsChange.bind(this))
    }

    removeEventListeners() {
        super.removeEventListeners()
        if (this.player) {
            this.player.off('ended', this.onPlayerEnded.bind(this))
        }
        if (this.audio) {
            this.audio.removeEventListener('timeupdate', this.onTimeUpdate.bind(this))
        }
        this.loopToggleTarget.removeEventListener('change', this.onLoopToggleChange.bind(this))
        this.startMinutesTarget.removeEventListener('change', this.onStartMinutesChange.bind(this))
        this.startSecondsTarget.removeEventListener('change', this.onStartSecondsChange.bind(this))
        this.endMinutesTarget.removeEventListener('change', this.onEndMinutesChange.bind(this))
        this.endSecondsTarget.removeEventListener('change', this.onEndSecondsChange.bind(this))
    }

    onAudioLoaded() {
        super.onAudioLoaded()
        this.setupDropdowns()
        this.updateLoop()
    }

    setupDropdowns() {
        const duration = Math.floor(this.audio.duration)
        const maxMinutes = Math.floor(duration / 60)
        const maxSeconds = duration % 60

        this.loopEnd = duration

        this.populateDropdown(this.startMinutesTarget, maxMinutes)
        this.populateDropdown(this.startSecondsTarget, 59)
        this.populateDropdown(this.endMinutesTarget, maxMinutes)
        this.populateEndSecondsDropdown(maxMinutes, maxSeconds)

        this.endMinutesTarget.value = maxMinutes
        this.endSecondsTarget.value = maxSeconds

        this.log(`Dropdowns set up with duration: ${duration}s`, "debug")
    }

    populateDropdown(dropdown, max) {
        dropdown.innerHTML = ''
        for (let i = 0; i <= max; i++) {
            const option = document.createElement('option')
            option.value = i
            option.textContent = i.toString().padStart(2, '0') + (dropdown.id.includes('Minutes') ? ' min' : ' sec')
            dropdown.appendChild(option)
        }
    }

    populateEndSecondsDropdown(selectedMinutes, maxSeconds) {
        const duration = this.audio.duration
        const maxPossibleSeconds = selectedMinutes === Math.floor(duration / 60)
            ? maxSeconds
            : 59
        this.populateDropdown(this.endSecondsTarget, maxPossibleSeconds)
    }

    updateLoop() {
        if (this.audio) {
            this.audio.loop = this.loopToggleTarget.checked
            if (this.audio.loop) {
                this.audio.currentTime = Number(this.loopStart)
            }
        }
        this.log(`Loop updated: ${this.audio.loop ? 'enabled' : 'disabled'}`, "debug")
    }

    onPlayerEnded() {
        if (this.loopToggleTarget.checked && this.player) {
            this.player.currentTime = this.loopStart
            this.player.play()
            this.log("Player ended, looping back to start", "debug")
        }
    }

    onTimeUpdate() {
        if (this.loopToggleTarget.checked && this.audio) {
            if (this.audio.currentTime >= this.loopEnd) {
                this.audio.currentTime = this.loopStart
                this.audio.play()
                this.log("Loop point reached, restarting", "debug")
            }
        }
    }

    validateLoopLength() {
        if (this.loopEnd - this.loopStart < this.minLoopLength) {
            if (this.loopStart + this.minLoopLength > this.audio.duration) {
                this.loopStart = Math.max(0, this.audio.duration - this.minLoopLength)
            }
            this.loopEnd = Math.min(this.loopStart + this.minLoopLength, this.audio.duration)
        }

        this.loopStart = Math.max(0, this.loopStart)
        this.loopEnd = Math.min(this.loopEnd, this.audio.duration)

        if (this.loopToggleTarget.checked && this.player) {
            this.player.currentTime = this.loopStart
        }

        this.updateLoopIndicator()
        this.updateLoopTimeDisplay()
        this.log(`Loop validated: ${this.loopStart}s to ${this.loopEnd}s`, "debug")
    }

    updateLoopTimeDisplay() {
        this.startMinutesTarget.value = Math.floor(this.loopStart / 60)
        this.startSecondsTarget.value = Math.floor(this.loopStart % 60)
        this.endMinutesTarget.value = Math.floor(this.loopEnd / 60)

        this.populateEndSecondsDropdown(Math.floor(this.loopEnd / 60), Math.floor(this.audio.duration % 60))
        this.endSecondsTarget.value = Math.floor(this.loopEnd % 60)
        this.log("Loop time display updated", "debug")
    }

    onLoopToggleChange() {
        this.updateLoop()
    }

    onStartMinutesChange(event) {
        this.loopStart = parseInt(event.target.value) * 60 + parseInt(this.startSecondsTarget.value)
        this.validateLoopLength()
    }

    onStartSecondsChange(event) {
        this.loopStart = parseInt(this.startMinutesTarget.value) * 60 + parseInt(event.target.value)
        this.validateLoopLength()
    }

    onEndMinutesChange(event) {
        const selectedMinutes = parseInt(event.target.value)
        const maxSeconds = selectedMinutes === Math.floor(this.audio.duration / 60)
            ? Math.floor(this.audio.duration % 60)
            : 59
        this.populateEndSecondsDropdown(selectedMinutes, maxSeconds)
        this.updateLoopEnd()
    }

    onEndSecondsChange(event) {
        this.updateLoopEnd()
    }

    updateLoopEnd() {
        const endMinutes = parseInt(this.endMinutesTarget.value)
        const endSeconds = parseInt(this.endSecondsTarget.value)
        this.loopEnd = Math.min(endMinutes * 60 + endSeconds, this.audio.duration)
        this.validateLoopLength()
    }

    setupDragAndDrop() {
        if (this.hasProgressBarTarget && this.hasStartHandleTarget && this.hasEndHandleTarget) {
            const options = { passive: false };

            this.boundStartDragging = this.startDragging.bind(this);
            this.boundDrag = this.drag.bind(this);
            this.boundStopDragging = this.stopDragging.bind(this);

            ['mousedown', 'touchstart'].forEach((eventType) => {
                this.startHandleTarget.addEventListener(eventType, this.boundStartDragging, options);
                this.endHandleTarget.addEventListener(eventType, this.boundStartDragging, options);
            });

            ['mousemove', 'touchmove'].forEach((eventType) => {
                document.addEventListener(eventType, this.boundDrag, options);
            });

            ['mouseup', 'touchend', 'touchcancel'].forEach((eventType) => {
                document.addEventListener(eventType, this.boundStopDragging, options);
            });

            this.log("Drag and drop event listeners set up", "debug");
        } else {
            this.log("Required targets are missing for drag and drop", "warn");
        }
    }

    removeDragAndDropListeners() {
        this.log("Removing drag and drop listeners", "debug");

        const targets = ['progressBar', 'startHandle', 'endHandle'];
        const boundFunctions = ['boundStartDragging', 'boundDrag', 'boundStopDragging'];

        // Check if all required targets and bound functions exist
        const allTargetsExist = targets.every(target => this[`has${target.charAt(0).toUpperCase() + target.slice(1)}Target`]);
        const allBoundFunctionsExist = boundFunctions.every(func => typeof this[func] === 'function');

        if (!allTargetsExist || !allBoundFunctionsExist) {
            this.log("Some required targets or bound functions are missing. Skipping listener removal.", "warn");
            return;
        }

        try {
            // Remove listeners from handle targets
            ['mousedown', 'touchstart'].forEach(eventType => {
                this.safeRemoveEventListener(this.startHandleTarget, eventType, this.boundStartDragging);
                this.safeRemoveEventListener(this.endHandleTarget, eventType, this.boundStartDragging);
            });

            // Remove document listeners
            ['mousemove', 'touchmove'].forEach(eventType => {
                this.safeRemoveEventListener(document, eventType, this.boundDrag);
            });

            ['mouseup', 'touchend', 'touchcancel'].forEach(eventType => {
                this.safeRemoveEventListener(document, eventType, this.boundStopDragging);
            });

            this.log("Successfully removed all drag and drop listeners", "info");
        } catch (error) {
            this.log(`Error removing drag and drop listeners: ${error.message}`, "error");
        }
    }

    safeRemoveEventListener(element, eventType, listener) {
        if (element && typeof element.removeEventListener === 'function' && typeof listener === 'function') {
            element.removeEventListener(eventType, listener);
            this.log(`Removed ${eventType} listener from ${element.tagName || 'document'}`, "debug");
        } else {
            this.log(`Failed to remove ${eventType} listener: Invalid element or listener`, "warn");
        }
    }

    startDragging(event) {
        const handle = event.target.classList.contains('start-handle') ? 'start' : 'end'
        this.log(`Start dragging ${handle}`, "debug")
        event.preventDefault()
        this.dragging = handle
        this.startX = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX
        this.startLeft = parseFloat(this[`${handle}HandleTarget`].style.left) || 0
        document.body.style.userSelect = 'none'
    }

    drag(event) {
        if (!this.dragging) return
        event.preventDefault()

        const clientX = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX
        const progressBarRect = this.progressBarTarget.getBoundingClientRect()
        const deltaX = clientX - progressBarRect.left
        const newLeft = Math.max(0, Math.min(100, (deltaX / progressBarRect.width) * 100))

        this[`${this.dragging}HandleTarget`].style.left = `${newLeft}%`

        const newTime = (newLeft / 100) * this.audio.duration
        if (this.dragging === 'start') {
            this.loopStart = Math.min(newTime, this.loopEnd - 1)
        } else {
            this.loopEnd = Math.max(newTime, this.loopStart + 1)
        }

        this.validateLoopLength()
        this.updateLoopIndicator()
        this.updateLoopTimeDisplay()
        this.log(`Dragging ${this.dragging} handle to ${newLeft}%`, "debug")
    }

    stopDragging(event) {
        this.log('Stop dragging', "debug")
        this.dragging = null
        document.body.style.userSelect = ''
    }

    updateLoopIndicator() {
        const startPercentage = (this.loopStart / this.audio.duration) * 100
        const endPercentage = (this.loopEnd / this.audio.duration) * 100
        this.loopIndicatorTarget.style.left = `${startPercentage}%`
        this.loopIndicatorTarget.style.width = `${endPercentage - startPercentage}%`
        this.startHandleTarget.style.left = `${startPercentage}%`
        this.endHandleTarget.style.left = `${endPercentage}%`
        this.log(`Loop indicator updated: ${startPercentage}% to ${endPercentage}%`, "debug")
    }
}