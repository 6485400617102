import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["select"]
    static values = { url: String }

    connect() {
        console.log("PracticeIntervalChangeController connected")
    }

    change() {
        console.log("Practice interval changed:", this.selectTarget.value)
        this.submitChange()
    }

    submitChange() {
        const data = new FormData()
        data.append('practice_interval', this.selectTarget.value)

        fetch(this.urlValue, {
            method: 'PATCH',
            body: data,
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                'Accept': 'text/vnd.turbo-stream.html'
            },
            credentials: 'same-origin'
        })
            .then(response => response.text())
            .then(html => Turbo.renderStreamMessage(html))
            .catch(error => console.error('Error:', error))
    }
}